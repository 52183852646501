<template>
  <div class='business-radar-create'>
    <router-header :routerHeaderInfo="routerHeaderInfo"></router-header>
    <a-form class="create-radar-form"
            ref="formRef"
            :model="radar"
            :rules="rules"
            hideRequiredMark
            labelAlign='left'
            :colon='false'>
      <a-form-item label="雷达标题"
                   name="title">
        <a-input class="word-title"
                 v-model:value="radar.title"
                 placeholder="请输入雷达标题名称" />
      </a-form-item>

      <div class="radar__content">
        <div class="radio-list">
          <a-radio-group v-model:value="radar.type">
            <a-radio value="link">链接</a-radio>
            <a-radio value="file">PDF</a-radio>
          </a-radio-group>
        </div>

        <div class="content__container">
          <div v-if="radar.type === 'link'"
               class="word-link">
            <a-form-item name="link"
                         :wrapper-col="{span: 22}">
              <a-input v-model:value="radar.link"
                       @keyup="getLinkInfo(radar)"
                       placeholder="链接地址请以http:// 或 https://开头">
              </a-input>
            </a-form-item>
            <a-form-item label="链接标题"
                         name="linkTitle"
                         :wrapper-col="{span: 13}">
              <a-input v-model:value="radar.linkTitle"></a-input>
            </a-form-item>
            <a-form-item label="链接摘要"
                         name="linkSummary"
                         :wrapper-col="{span: 13}">
              <a-input v-model:value="radar.linkSummary"></a-input>
            </a-form-item>
            <a-form-item label="链接封面"
                         name='linkCover'
                         :wrapper-col="{span: 19}">
              <upload-image :imageUrl="radar.linkCover"
                            @child-image="handleLinkImg"
                            :apiName="'businessRadarLink'">
              </upload-image>
            </a-form-item>
          </div>

          <template v-if="radar.type === 'file'">
            <a-form-item name='no'
                         :wrapper-col="{span: 19}">
              <upload-file apiName='businessRadar'
                           :fileInfo="radar"
                           @child-image="handleFileUpload">
              </upload-file>
              <span class="radar-file-introduce">上传文件将自动转成网页链接，员工发送给客户后可记录客户打开数据。</span>
            </a-form-item>
          </template>

        </div>

        <span class="introduce">
          <Icon class="mr2"
                type='iconshuoming'></Icon>当客户点击雷达链接时，发送链接的员工会收到提醒消息。
        </span>
      </div>

    </a-form>
    <div class="save-btn">
      <a-button class="btn"
                type="primary"
                :loading="submitLoading"
                @click="saveRadar">
        确定
      </a-button>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive } from "vue";
import { Radio, message } from "ant-design-vue";
import { useRouter } from "vue-router";
import _ from "lodash";

import RouterHeader from "@/components/RouterHeader.vue";
import UploadImage from "@/components/UploadImage.vue";
import UploadFile from "@/components/UploadFile.vue";
import Icon from "@/components/SvgIcon.vue";

import businessRadarApi from "@/service/api/businessRadar";

export default defineComponent({
  name: "BusinessRadarCreate",
  props: [],
  emits: [],
  components: {
    ARadio: Radio,
    ARadioGroup: Radio.Group,

    RouterHeader,
    UploadImage,
    UploadFile,
    Icon,
  },
  setup() {
    const routerHeaderInfo = [
      {
        path: "/marketingUtil/businessRadar",
        name: "商机雷达",
      },
      {
        name: "新建雷达",
      },
    ];

    const radar = reactive({
      type: "link",
    });

    const rules = {
      title: [
        {
          required: true,
          whitespace: true,
          message: "雷达标题不能为空",
          trigger: "blur",
        },
        {
          max: 30,
          message: "雷达标题长度应为1-30字",
          trigger: "change",
        },
      ],
      link: [
        {
          required: true,
          whitespace: true,
          message: "链接地址不能为空",
          trigger: "blur",
        },
      ],
      linkTitle: [
        {
          required: true,
          whitespace: true,
          message: "链接标题不能为空",
          trigger: "blur",
        },
        {
          max: 40,
          message: "链接标题最长40字",
          trigger: "change",
        },
      ],
      linkSummary: [
        {
          required: true,
          whitespace: true,
          message: "链接摘要不能为空",
          trigger: "blur",
        },
      ],
      linkCover: [
        {
          required: true,
          message: "请上传链接封面",
          trigger: "blur",
        },
      ],
      no: [
        {
          required: true,
          message: "请上传PDF文件",
          trigger: "blur",
        },
      ],
    };

    const getLinkInfo = _.debounce(async () => {
      if (!/^(http|https):/.test(radar.link)) {
        radar.link = "https://" + radar.link;
      }
      let urlInfo = await businessRadarApi.getUrlBaseInfo({ url: radar.link });

      if (urlInfo.title.length >= 50) {
        urlInfo.title = urlInfo.title.slice(0, 40);
      }

      radar.linkTitle = urlInfo.title;
      radar.linkSummary = urlInfo.desc;
      radar.linkCover = urlInfo.cover;
      formRef.value.clearValidate([
        "linkCover",
        "linkTitle",
        "linkSummary",
        "link",
      ]);
    }, 800);
    function handleLinkImg(res) {
      radar.linkCover = res;
      formRef.value.clearValidate("linkCover");
    }
    function handleFileUpload(res) {
      console.log(res);
      _.assign(radar, res);
      console.log(radar);
      formRef.value.clearValidate("no");
    }

    const formRef = ref();
    const submitLoading = ref(false);
    const router = new useRouter();

    function saveRadar() {
      formRef.value.validate().then(async () => {
        submitLoading.value = true;
        await businessRadarApi.create(radar);
        submitLoading.value = false;
        message.success("创建成功");
        router.push({ name: "businessRadar_index" });
      });
    }

    return {
      routerHeaderInfo,

      radar,
      rules,

      getLinkInfo,
      handleLinkImg,
      handleFileUpload,

      formRef,
      submitLoading,
      saveRadar,
    };
  },
});
</script>